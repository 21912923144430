// @flow strict
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { useSiteMetadata } from '../../../hooks';
import type { Node } from '../../../types';
import styles from './ShareButtons.module.scss';

type Props = {
  post: Node,
};

const ShareButtons = ({ post }: Props) => {
  const { slug } = post.fields;
  const { title } = post.frontmatter;
  const { author, url } = useSiteMetadata();
  const postUrl = url + slug;

  return (
    <div>
      <p className={styles['label']}>この記事を SNS でシェアする</p>
      <span className={styles['button']}>
        <TwitterShareButton
          title={title}
          via={author.contacts.twitter}
          url={postUrl}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </span>
      <span className={styles['button']}>
        <FacebookShareButton url={postUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </span>
      <span className={styles['button']}>
        <HatenaShareButton title={title} url={postUrl}>
          <HatenaIcon size={32} round />
        </HatenaShareButton>
      </span>
    </div>
  );
};

export default ShareButtons;
