// @flow strict
import React from 'react';
import type { Node } from '../../../types';
import styles from './RecommendPosts.module.scss';

type Props = {
  post: Node,
};

const RecommendPost = (url: string) => {
  const imgSrc = `${url}/thumbnail.png`;

  return (
    <a class={styles['card']} href={url}>
      <figure class={styles['card__img-wrapper']}>
        <img class={styles['card__img']} src={imgSrc}></img>
      </figure>
    </a>
  );
};

const RecommendPosts = ({ post }: Props) => {
  const { title } = post.frontmatter;
  const { autoRecommendPosts } = post.fields;

  if (autoRecommendPosts === undefined || autoRecommendPosts.length === 0) {
    return null;
  }

  console.info(`auto recommend: title = ${title}, autoRecommendPosts = ${JSON.stringify(autoRecommendPosts)}`);
  const recommendPosts = autoRecommendPosts.map((slug) => `https://www.kanzennirikaisita.com/posts/${slug}`);

  return (
    <div>
      <h2 class={styles['h2']}>おすすめ記事</h2>
      <div class={styles['cards']}>
        {recommendPosts.map((r) => RecommendPost(r))}
      </div>
    </div>
  );
};

export default RecommendPosts;
