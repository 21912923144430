// @flow strict
import { Link } from 'gatsby';
import React from 'react';
import type { Node } from '../../types';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import styles from './Post.module.scss';
import RecommendPosts from './RecommendPosts';
import ShareButtons from './ShareButtons';
import Tags from './Tags';

type Props = {
  post: Node
}

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  const buttomGoogleAdsenceHtml = `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- ページ下部_ディスプレイ広告_横長タイプ -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2892333763960650"
     data-ad-slot="7358091907"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
`;

  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">
        All Articles
      </Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <center>
          <ShareButtons post={post} />
        </center>
        <RecommendPosts post={post} />
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <div dangerouslySetInnerHTML={{ __html: buttomGoogleAdsenceHtml }}></div>
        <Author />
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
